<template>
  <div class="inicial-container">
    <h3 class="disclaimer">
      Integração com o <img :src="skynetLogo" class="skynetLogo" />
      do Projeto:
    </h3>
    <h3 class="disclaimer id">"{{ projectName }}"</h3>
    <div class="content container-fluid text-center">
      <md-card class="boxVarResposta">
        <md-card-header
          class="md-card-header-icon md-card-header-blue skynetIcon"
        >
          <div class="card-icon card">
            <span class="material-symbols-outlined icon"> rocket_launch </span>
          </div>
        </md-card-header>
        <md-card-content class="label">
          <TituloBox
            :label="'Configuração do parâmetro Variável Resposta:'"
            :iconLabel="'task_alt'"
          />
        </md-card-content>
        <md-card-content class="field">
          <div class="respostaBox">
            <div class="left">
              <h5 class="title">
                Selecione a coluna referente à Variável Resposta:
                <span class="material-symbols-outlined helpIcon">
                  help
                  <md-tooltip
                    class="md-layout-item md-size-33 md-small-size-66 text-left"
                  >
                    Selecione a coluna referente à
                    <strong>Variável Resposta</strong>.<br />
                    <strong>Validações de coluna:</strong> <br />
                    <li>São permitidos os valores "0", "1" e "NA".</li>
                    <li>
                      Deve necessariamente ter "0" e "1" e não somente "0" ou
                      somente "1".
                    </li>
                  </md-tooltip>
                </span>
              </h5>
              <md-field class="selectCol" required>
                <label for="varRespSelecionada"
                  >Selecione a variavel resposta*
                </label>
                <md-select
                  name="varRespSelecionada"
                  id="varRespSelecionada"
                  @input="verificaVariavelResposta"
                  v-model="varRespSelecionada"
                  :disabled="availableCols.length <= 1"
                  :placeholder="
                    varRespMemory != ''
                      ? varRespMemory + ' (Selecionado)'
                      : optionsListResposta.length <= 1
                      ? 'Nenhuma coluna identificada'
                      : 'Selecione a variavel Resposta'
                  "
                >
                  <div class="textSearch">
                    <md-input
                      v-model="searchValue"
                      class="textSearchInput"
                      placeholder="Procure uma coluna"
                    >
                    </md-input>
                  </div>
                  <md-option
                    v-for="item in optionsListResposta"
                    :key="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>
              <md-card class="escolhaMaus">
                <strong style="font-size: 1rem" class="align-left"
                  >Selecione o valor que representa os eventos 'MAUS'*</strong
                >
                <div class="selectMaus">
                  <md-radio
                    class="valorMaus"
                    v-model="respostaMaus"
                    @click="verificaMaus"
                    value="Valor_0"
                    >0 = Maus</md-radio
                  >
                  <md-radio
                    class="valorMaus"
                    v-model="respostaMaus"
                    @click="verificaMaus"
                    value="Valor_1"
                    >1 = Maus</md-radio
                  >
                </div>
              </md-card>
            </div>
            <div class="right">
              <div class="pieChart">
                <div class="pieBox" v-if="varResposta && !errorMsgResposta">
                  <p class="chartTitle">
                    <strong>Representatividade da Variável Resposta</strong>
                  </p>
                  <PieIntegr
                    :data="Object.values(this.varResposta)"
                    :labels="Object.keys(this.varResposta)"
                    :variavel="varResposta"
                    :id="3"
                  />
                </div>
                <div
                  class="pieBox"
                  id="pieChartDefault"
                  v-if="!varResposta && !errorMsgResposta && !liberaLoad"
                >
                  <PieVazio :id="1" />
                </div>
              </div>
            </div>
          </div>
          <div class="safraBox">
            <SafraConceito
              ref="safraConceito"
              :identificador="identificador"
              :varRespSelecionada="varRespSelecionada"
              :varResposta="varResposta"
              :errorMsgResposta="errorMsgResposta"
              :liberaSafraDataRef="liberaSafraDataRef"
              :cabecalhoMapeadoAPI="cabecalhoMapeadoAPI"
              :cabecalhoMapeadoUser="cabecalhoMapeadoUser"
              :valido="valido"
              :liberaLoad="liberaLoad"
            />
          </div>
        </md-card-content>
        <md-card-content class="footerVarResp">
          <button class="button-backstest" @click="goInteg0">ANTERIOR</button>
          <button
            class="button-backstest"
            :disabled="!valido"
            @click="goInteg2"
          >
            PRÓXIMA
          </button>
        </md-card-content>
        <div class="spinnerBox text-center" v-if="liberaLoad && !varResposta">
          <Loading class="spinner" />
        </div>
      </md-card>
    </div>
  </div>
</template>

<script>
import { historicoService } from "@/services";
import { useSkynetStore } from "@/stores/skynet";
import { mapWritableState, mapActions } from "pinia";
import { useSkynetMemoryStore } from "@/stores/skynetMemory";
import TituloBox from "@/components/Visuais/TituloBox.vue";
import Loading from "@/components/Loading";
import PieIntegr from "@/components/Integracao/Charts/PieIntegr";
import PieVazio from "@/components/Integracao/Charts/PieVazio";
import SafraConceito from "@/components/Integracao/safraConceito";
import Swal from "sweetalert2";

export default {
  name: "VariavelResposta",

  components: {
    TituloBox,
    Loading,
    PieIntegr,
    PieVazio,
    SafraConceito,
  },

  props: {
    cabecalhoMapeadoAPI: Array,
    cabecalhoMapeadoUser: Array,
    liberaSafraDataRef: Boolean,
  },

  data() {
    return {
      varRespSelecionada: "",
      varRespSelecionadaBef: "",
      resLote: [],
      varResposta: null,
      identificador: this.$route.params.id,
      skynetLogo: require("/public/img/Skynet.png"),
      valido: false,
      respostaMaus: "Valor_1",
      liberaLoad: false,
      errorMsgResposta: "",
      searchValue: "",
      cabecalho: [],
    };
  },

  methods: {
    async recuperaLoteInfo(identificador) {
      const objetoHistorico = { identificacao_execucao: identificador };
      const query = new URLSearchParams(objetoHistorico);

      try {
        this.resLote = await historicoService.recuperaDadosHistoricoLotes(
          query.toString()
        );
      } catch (error) {}
      let res = this.resLote[0].colunas_nao_mapeadas_pelo_usuario.split(";");

      res.forEach((atributo) => {
        atributo = atributo.replaceAll(/(\r\n|\n|\r|"|')/gm, "");
        this.cabecalho.push(atributo);
      });

      this.setCols(this.cabecalho);
      this.setFormatData(
        this.resLote[0].mapeamento_formatos,
        this.resLote[0].cod_ref
      );

      const indexData = this.cabecalhoMapeadoAPI.indexOf("data_ref");
      const indexDoc = this.cabecalhoMapeadoAPI.indexOf("cpf_cnpj");

      this.setDataRefColumn(this.cabecalhoMapeadoUser[indexData]);
      this.setDocColumn(this.cabecalhoMapeadoUser[indexDoc]);
    },

    async verificaVariavelResposta() {
      this.$emit("variavelResposta", this.varRespSelecionada);
      this.varResposta = null;
      this.liberaLoad = true;
      this.updateCol(this.varRespSelecionada, this.varRespSelecionadaBef);
      this.varRespSelecionadaBef = this.varRespSelecionada;

      const objetoHistorico = {
        identificacao_execucao: this.identificador,
        coluna_principal: this.varRespSelecionada,
        limite_contagem: 50,
      };
      const query = new URLSearchParams(objetoHistorico);
      try {
        this.varResposta = await historicoService.recuperaVariavelAmostra(
          query.toString()
        );
        this.liberaLoad = false;
        this.validaAmostra();
        this.errorMsgResposta = null;
      } catch (error) {
        this.liberaLoad = false;
        this.valido = false;
        this.varResposta = null;
        this.errorMsgResposta = error.toString();
      }
    },
    validaAmostra() {
      if (
        (this.varResposta["0"] > 1 &&
          this.varResposta["1"] > 1 &&
          Object.keys(this.varResposta).length == 2) ||
        (this.varResposta["0"] > 1 &&
          this.varResposta["1"] > 1 &&
          this.varResposta["NA"] > 1 &&
          Object.keys(this.varResposta).length < 4)
      ) {
        this.valido = true;
        this.setVarResposta(this.varRespSelecionada);

        this.$refs.safraConceito.recuperaEventoPorSafra();
      } else {
        this.valido = false;
        Swal.fire({
          icon: "error",
          title: "Variável Resposta Inválida",
          text: "Os valores da Variável Resposta devem ser 0 e 1 ou NA, com quantidade em cada um deles maior do que um.",
          buttonsStyling: false,
          customClass: {
            confirmButton: "md-button md-warning",
          },
        });
      }
    },

    verificaMaus() {
      this.setMaus(this.respostaMaus);
    },

    goInteg0() {
      this.$router.push("/integracoes_skynet/" + this.identificador + "/0");
    },

    goInteg2() {
      this.$router.push("/integracoes_skynet/" + this.identificador + "/2");
    },

    ...mapActions(useSkynetStore, [
      "updateCol",
      "setCols",
      "setFormatData",
      "setDataRefColumn",
      "setDocColumn",
    ]),
    ...mapActions(useSkynetMemoryStore, [
      "setProjectName",
      "setVarResposta",
      "setMaus",
    ]),
  },

  created() {
    if (this.varRespMemory != "") {
      this.varRespSelecionada = this.varRespMemory;
      this.respostaMaus = this.respMau;
      this.verificaVariavelResposta();
      this.searchValue = "";
    } else {
      this.recuperaLoteInfo(this.identificador);
    }
    if (!this.projectName) {
      this.$router.push("/integracoes_skynet/" + this.identificador + "/0");
    }
  },

  mounted() {},

  computed: {
    ...mapWritableState(useSkynetStore, [
      "availableCols",
      "dataRefColumn",
      "docColumn",
    ]),
    ...mapWritableState(useSkynetMemoryStore, [
      "varRespMemory",
      "respMau",
      "projectName",
    ]),

    optionsListResposta() {
      if (this.searchValue.trim().length > 0) {
        return this.availableCols.filter((column) =>
          column.toLowerCase().includes(this.searchValue.trim())
        );
      }
      return this.availableCols;
    },
  },
  watch: {
    respostaMaus(novo, velho) {
      this.setMaus(novo);
    },
  },
};
</script>

<style lang="scss" scoped>
.inicial-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}
.disclaimer {
  font-weight: 500;
  font-size: 1.5rem !important;
  text-align: center;
  margin: 0 !important;
  word-wrap: break-all;
}
.id {
  font-style: italic;
  word-wrap: break-all;
  padding: 5px 10px 5px 10px;
  border-radius: 6px;
  font-weight: 600;
}
.content {
  width: 100%;
  min-height: 100%;
  @include flex-center(column);
}
@media (min-width: 1025px) {
  .inicial-container .disclaimer {
    font-size: 2.2rem;
  }
}
.card {
  padding: 10px !important;
  border: none;
  height: 64px !important;
  top: -1rem;
}
.boxVarResposta {
  background-color: #f9f7ff;
  border: 1px solid var(--accent-60);
  border-radius: 20px;
  padding: 0 0 0 0 !important;
  min-height: 500px;
  width: 90% !important;
  flex-wrap: wrap;
  margin: 10px;
  display: flex;
  flex-direction: column !important;

  @include mediaQueryMax(medium) {
    min-height: 300px;
    width: 65% !important;
  }
  @include mediaQueryMax(small) {
    min-height: 300px;
    width: 85% !important;
  }
}
.skynetIcon {
  display: flex;
  align-self: flex-end;
  z-index: 1;
  height: 0px;
  @include mediaQueryMax(small) {
    display: none;
  }
}
.skynetLogo {
  height: 35px;
  margin: 10px 0;
}
.title {
  margin: 10px 0;
  display: flex;
  font-size: 1rem;
  align-items: flex-start;
  align-self: flex-start;
  justify-content: flex-start;
  line-height: 2rem;
}
.helpIcon {
  cursor: pointer;
  color: #209cee;
  font-size: 1.5rem;
  margin-left: 0.3rem;
}

.label {
  display: flex;
  justify-content: flex-start;
  margin: 5px 0px 0 0 !important;
  padding: 0px 20px;
  width: 100%;
}
.field {
  min-height: 400px;
  display: flex;
  margin: 0%;
  width: 100%;
  padding: 0.2rem 1rem 1rem 1rem;
  flex-direction: column;

  @include mediaQueryMax(medium) {
    flex-direction: column;
    padding: 0.2rem 1.5rem 1rem 1.5rem;
  }

  @include mediaQueryMax(small) {
    flex-direction: column;
    min-height: 250px;
    padding: 0.2rem 1.5rem 0.2rem 1.5rem;
  }
}
.respostaBox {
  display: flex;
  height: 300px;

  @include mediaQueryMax(medium) {
    flex-direction: column;
    height: auto;
  }
}
.safraBox {
  display: flex;
  max-height: 50%;
}
.footerVarResp {
  height: 10%;
  display: flex;
  justify-content: flex-end;
  margin: 0;
  padding: 5px 15px 15px 15px;
  background-color: transparent;
  @include mediaQueryMax(small) {
    flex-direction: column;
    margin-top: 1rem;
  }
}
.selectCol {
  width: 15rem;
  align-self: flex-start;

  @include mediaQueryMax(small) {
    width: 100%;
  }
}
.selectMaus {
  width: 12rem;
  height: 2rem;
  @include mediaQueryMax(small) {
    width: 100%;
  }
}
.valorMaus {
  margin: 10px 10px 5px 0 !important;
}

.textSearch {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
}
.textSearchInput {
  display: flex;
  width: 95%;
  height: 35px;
  border-width: 0px 0px 0.1rem 0px;
  font-size: small;
}
.escolhaMaus {
  display: flex;
  align-items: center;
  margin: 10px 0 10px 0;
  padding: 10px;
  max-width: 90%;
  min-height: 80px;
  max-height: 120px;

  @include mediaQueryMax(medium) {
    max-width: 90%;
  }
  @include mediaQueryMax(small) {
    max-width: 100%;
  }
}
.left {
  width: 50%;
  min-height: 100%;
  margin: 0 5px 0 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  @include mediaQueryMax(medium) {
    width: 100%;
  }

  @include mediaQueryMax(small) {
    width: 100%;
    min-height: 200px;
  }
}
.right {
  width: 50%;
  min-height: 100%;
  margin: 0 0 0 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  @include mediaQueryMax(medium) {
    width: 100%;
  }

  @include mediaQueryMax(small) {
    width: 100%;
    min-height: 200px;
  }
}
.pieChart {
  width: 100%;
  height: 100%;
  padding: 0%;
  margin: 0 !important;
  @include flex-center(column);
}

.pieBox {
  width: 100%;
  height: 100%;
  @include flex-center(column);
  align-items: center;
  justify-content: flex-start;
}
.chartTitle {
  margin: 10px;
}
.icon {
  color: #f9f7ff;
  width: 48px;
  height: 48px;
  font-weight: 600;
  font-size: 2.5rem;
}
.spinnerBox {
  display: flex;
}
.spinner {
  // width: 20px;
  z-index: 10;
  position: absolute;
  left: 40%;
  top: 40%;
}
</style>
