<template>
  <div class="inicial-container">
    <h3 class="disclaimer">
      Integração com o <img :src="skynetLogo" class="skynetLogo" />
      do Projeto:
    </h3>
    <h3 class="disclaimer id">"{{ projectName }}"</h3>
    <div class="content container-fluid text-center">
      <md-card class="boxBacktestExt">
        <md-card class="boxBacktest">
          <md-card-header
            class="md-card-header-icon md-card-header-blue skynetIcon"
          >
            <div class="card-icon card">
              <span class="material-symbols-outlined icon">
                rocket_launch
              </span>
            </div>
          </md-card-header>
          <md-card-content class="label">
            <TituloBox
              :label="'Resumo das Integrações:'"
              :iconLabel="'task_alt'"
            />
          </md-card-content>
          <md-card-content class="field">
            <div class="left">
              <md-card class="cardInfo">
                <div class="cardField">
                  <h5 class="textTitle">
                    Parâmetro Variável Resposta:
                    <span class="material-symbols-outlined helpIcon">
                      help
                      <md-tooltip
                        class="md-layout-item md-size-33 md-small-size-66"
                      >
                        Clique em <strong>'Editar Var. Resposta'</strong> para
                        retornar ao <strong>passo 1</strong>.
                        <br />
                      </md-tooltip>
                    </span>
                  </h5>
                  <strong class="infosTitle">Variável Resposta:</strong>
                  <md-card class="infoLote">{{ varRespMemory }}</md-card>
                  <strong class="infosTitle">Safra:</strong>
                  <md-card class="infoLote fileNameInfoLote">
                    {{ dataRefColumn }}
                  </md-card>
                </div>
                <div class="cardFooter">
                  <div class="buttonCardInfo">
                    <div class="boxInfo">
                      <router-link
                        :to="'/integracoes_skynet/' + this.identificador + '/1'"
                        class="router"
                      >
                        <div class="editButton">
                          <span
                            class="material-symbols-outlined iconMap"
                            style="margin-bottom: 3px"
                            >edit_square</span
                          >
                          <span>EDITAR VAR. RESPOSTA</span>
                        </div>
                        <md-tooltip md-direction="bottom">
                          Voltar para o passo 1.
                        </md-tooltip>
                      </router-link>
                    </div>
                  </div>
                </div>
              </md-card>
              <md-card class="cardInfo">
                <div class="cardField">
                  <h5 class="textTitle">
                    Parâmetros Variáveis Amostra:
                    <span class="material-symbols-outlined helpIcon">
                      help
                      <md-tooltip
                        class="md-layout-item md-size-33 md-small-size-66"
                      >
                        Clique em <strong>'Editar Var. Amostras'</strong> para
                        retornar ao <strong>passo 2</strong>.
                        <br />
                      </md-tooltip>
                    </span>
                  </h5>
                  <strong class="infosTitle">Amostra Principal:</strong>
                  <md-card class="infoLote">{{ varAmostraMemory[0] }}</md-card>
                  <strong class="infosTitle">Amostras Desafiantes:</strong>
                  <div class="infos">
                    <div
                      v-for="(amost, index) in this.varAmostraMemory"
                      :key="amost"
                    >
                      <md-card
                        class="infoLote fileNameInfoLote"
                        v-if="index != 0"
                      >
                        {{ amost }}
                      </md-card>
                    </div>
                    <md-card
                      class="infoLote fileNameInfoLote"
                      v-if="Object.values(this.varAmostraMemory).length == 1"
                    >
                      Nenhuma Amostra Desafiante Selecionada
                    </md-card>
                  </div>
                  <strong class="infosTitle">Amostras de Treino:</strong>
                  <div class="infos">
                    <div
                      v-for="(amost, index) in this.varAmostraTreinoMemory"
                      :key="index"
                    >
                      <md-card class="infoLote fileNameInfoLote">
                        {{ amost }}
                      </md-card>
                    </div>
                  </div>
                </div>
                <div class="cardFooter">
                  <div class="buttonCardInfo">
                    <div class="boxInfo">
                      <router-link
                        :to="'/integracoes_skynet/' + this.identificador + '/2'"
                        class="router"
                      >
                        <div class="editButton">
                          <span
                            class="material-symbols-outlined iconMap"
                            style="margin-bottom: 3px"
                            >edit_square</span
                          >
                          <span>EDITAR VAR. AMOSTRAS</span>
                        </div>
                        <md-tooltip md-direction="bottom">
                          Voltar para o passo 2.
                        </md-tooltip>
                      </router-link>
                    </div>
                  </div>
                </div>
              </md-card>
            </div>
            <div class="right">
              <md-card class="cardInfo">
                <div class="cardField">
                  <h5 class="textTitle">
                    Parâmetros Variáveis Chave:
                    <span class="material-symbols-outlined helpIcon">
                      help
                      <md-tooltip
                        class="md-layout-item md-size-33 md-small-size-66"
                      >
                        Clique em <strong>'Editar Var. Chaves'</strong> para
                        retornar ao <strong>passo 3</strong>.
                        <br />
                      </md-tooltip>
                    </span>
                  </h5>
                  <strong class="infosTitle"
                    >Variáveis Reservadas Selecionadas:</strong
                  >
                  <div
                    class="infos infoOverflow"
                    v-if="this.varReservadasMemory.length > 0"
                  >
                    <div
                      v-for="reserv in this.varReservadasMemory"
                      :key="reserv"
                    >
                      <md-card class="infoLote fileNameInfoLote">
                        {{ reserv }}
                      </md-card>
                    </div>
                  </div>
                  <md-card class="infoLote fileNameInfoLote" v-else>
                    Nenhuma Variável Reservada Selecionada
                  </md-card>
                  <strong class="infosTitle">Covariáveis Selecionadas:</strong>
                  <div
                    class="infos infoOverflow"
                    v-if="this.covarSelecionadasMemory.length > 0"
                  >
                    <div
                      v-for="covar in this.covarSelecionadasMemory"
                      :key="covar"
                    >
                      <md-card class="infoLote fileNameInfoLote">
                        {{ covar }}
                      </md-card>
                    </div>
                  </div>
                  <md-card class="infoLote fileNameInfoLote" v-else>
                    Nenhuma Covariável Selecionada
                  </md-card>
                </div>
                <div class="cardFooter">
                  <div class="buttonCardInfo">
                    <div class="boxInfo">
                      <router-link
                        :to="'/integracoes_skynet/' + this.identificador + '/3'"
                        class="router"
                      >
                        <div class="editButton">
                          <span
                            class="material-symbols-outlined iconMap"
                            style="margin-bottom: 3px"
                            >edit_square</span
                          >
                          <span>EDITAR VAR. CHAVES</span>
                        </div>
                        <md-tooltip md-direction="bottom">
                          Voltar para o passo 3.
                        </md-tooltip>
                      </router-link>
                    </div>
                  </div>
                </div>
              </md-card>
              <md-card class="cardInfo">
                <div class="cardField">
                  <h5 class="textTitle">
                    Parâmetros Linguagem e Abordagem:
                    <span class="material-symbols-outlined helpIcon">
                      help
                      <md-tooltip
                        class="md-layout-item md-size-33 md-small-size-66"
                      >
                        Clique em <strong>'Editar Ling. ou Abord.'</strong> para
                        retornar ao <strong>passo 4</strong>.
                        <br />
                      </md-tooltip>
                    </span>
                  </h5>
                  <strong class="infosTitle">Linguagem:</strong>
                  <div class="infos">
                    <md-card class="infoLote fileNameInfoLote">
                      {{ linguagemMemory.toUpperCase() }}
                    </md-card>
                  </div>
                  <strong class="infosTitle">Abordagem:</strong>
                  <div class="infos infoOverflow">
                    <div
                      v-for="abordagem in this.nomeAbordagem"
                      :key="abordagem"
                    >
                      <md-card class="infoLote fileNameInfoLote">
                        {{ abordagem }}
                      </md-card>
                    </div>
                  </div>
                </div>
                <div class="cardFooter">
                  <div class="buttonCardInfo">
                    <div class="boxInfo">
                      <router-link
                        :to="'/integracoes_skynet/' + this.identificador + '/4'"
                        class="router"
                      >
                        <div class="editButton">
                          <span
                            class="material-symbols-outlined iconMap"
                            style="margin-bottom: 3px"
                            >edit_square</span
                          >
                          <span>EDITAR LING. OU ABORD.</span>
                        </div>
                        <md-tooltip md-direction="bottom">
                          Voltar para o passo 4.
                        </md-tooltip>
                      </router-link>
                    </div>
                  </div>
                </div>
              </md-card>
            </div>
          </md-card-content>
          <md-card-content class="footer">
            <button class="button-backstest buttonResume" @click="goInteg4">
              ANTERIOR
            </button>
            <span class="buttonBox" :id="'buttonBox'">
              <button
                class="button-backstest buttonResume"
                @click="verificaAbordagem()"
                :disabled="gruposSkynet == ''"
              >
                ENVIAR
              </button>
              <md-tooltip
                v-if="gruposSkynet != ''"
                :target="'buttonBox'"
                triggers="hover"
                placement="bottom"
                variant="secondary"
                class="tooltipResults"
                boundary="window"
              >
                Submeter Integrações com o SKYNET
              </md-tooltip>
              <md-tooltip
                v-if="gruposSkynet == ''"
                :target="'buttonBox'"
                md-direction="bottom"
                md-delay="1000"
              >
                Usuário não possui permissão para submeter Integrações com o
                SKYNET.
              </md-tooltip>
            </span>
          </md-card-content>
          <div v-if="liberaLoad">
            <Loading class="spinner" />
          </div>
        </md-card>
      </md-card>
    </div>
  </div>
</template>

<script>
import { userService } from "@/services";
import { skynetService } from "@/services";
import { useSkynetStore } from "@/stores/skynet";
import { useSkynetMemoryStore } from "@/stores/skynetMemory";
import { mapWritableState, mapActions } from "pinia";
import TituloBox from "@/components/Visuais/TituloBox.vue";
import Swal from "sweetalert2";
import Loading from "@/components/Loading";

export default {
  name: "VariavelResposta",

  components: {
    TituloBox,
    Loading,
  },
  props: {},

  data() {
    return {
      identificador: this.$route.params.id,
      skynetLogo: require("/public/img/Skynet.png"),
      nomeAbordagem: [],
      textoParametros: "",
      produtoSkynet: "",
      amostrasTreino: "",
      resultadoIntegracao: null,
      errorAlertIntegr: "",
      liberaLoad: false,
      counter: 0,
      nomeAmigavel: "",
      grupos: [],
      gruposSkynet: "",
    };
  },

  methods: {
    async recuperaDadosUsuario() {
      userService.recuperaDadosUsuarioLogado().then(
        (usuario) => {
          this.$emit("recuperaUsuario", usuario);
          this.nomeAmigavel = usuario.NomeAmigavel;
          this.grupos = usuario.Grupos;

          this.verificaPermissoes();
        },
        (error) => {
          this.mensagemErroCarregamento =
            "Erro ao carregar dados do usuário logado.";
        }
      );
    },
    verificaPermissoes() {
      this.grupos.forEach((grupo) => {
        if (
          grupo == "Usuários do Skynet" ||
          grupo == "Administradores do Skynet"
        ) {
          this.gruposSkynet = grupo;
        }
      });
    },

    verificaAbordagem() {
      this.resetInteg();
      this.liberaLoad = true;
      for (let i in this.varAmostraMemory) {
        let covariaveis = this.covarSelecionadasMemory.join(",");
        let reservadas = this.reservadasAtualizadasMemory.join(",");
        this.amostrasTreino = this.varAmostraTreinoMemory[i];

        //quando trabalhar com outros layouts de lote, mudar cpf e data

        this.textoParametros =
          "nomeProjeto=" +
          this.projectName +
          ";" +
          "variavelResposta=" +
          this.varRespMemory +
          ";" +
          "variavelAmostra=" +
          this.varAmostraMemory[i] +
          ";" +
          "flagMaus=" +
          this.respMau +
          ";" +
          "nDoc=cpf_cnpj" +
          ";" +
          "dataProposta=data_ref" +
          ";" +
          "covariaveis=" +
          covariaveis +
          ";" +
          "variaveisChaves=" +
          reservadas +
          ";" +
          "linguagemDeploy=" +
          this.linguagemMemory +
          ";" +
          "trainSample=" +
          this.amostrasTreino +
          ";" +
          "tabelaDados=TB_FAST_MODEL;" +
          "baseLine=Central;" +
          "classeValoresAusentes=Similar;" +
          "xgbApply=Falso;linearidade=Verdadeiro;" +
          "figuras=Falso;dbClass=Verdadeiro;" +
          "dbCrames=Verdadeiro;" +
          "dbModel=Verdadeiro;" +
          "tipoOtimizacao=AlgoritmoGenetico;" +
          "ngenMaximas=50;" +
          "populacao=50";

        if (this.nomeAbordagem.includes("Logistic Reg.")) {
          this.produtoSkynet = "FastModel";
          this.enviaIntegracao();
        }

        if (
          this.nomeAbordagem.includes("LightGBM") ||
          this.nomeAbordagem.includes("XGBoost")
        ) {
          this.nomeAbordagemML = this.nomeAbordagem.filter(
            (x) => x != "Logistic Reg."
          );

          let abord = this.nomeAbordagemML.join(",");

          this.textoParametros =
            this.textoParametros + ";" + "algoritmo=" + abord;

          this.produtoSkynet = "MachineLearning";
          this.enviaIntegracao();
        }
      }
    },

    async enviaIntegracao() {
      // this.textoParametros = this.textoParametros.replaceAll(".", "_");
      this.counter++;
      var objetoConsulta = {};
      objetoConsulta["IdentificadorLote"] = this.identificador;
      objetoConsulta["Produto"] = this.produtoSkynet;
      objetoConsulta["TextoParametros"] = this.textoParametros;

      try {
        this.resultadoIntegracao = await skynetService.submeteIntegracaoSkynet(
          objetoConsulta
        );
        this.showAlert(true);
        this.setResultadoIntegracao(
          this.resultadoIntegracao,
          this.errorAlertIntegr,
          this.counter
        );
      } catch (error) {
        this.showAlert(false);
        this.errorAlertIntegr = error.toString();
        this.setResultadoIntegracao(
          this.resultadoIntegracao,
          this.errorAlertIntegr,
          this.counter
        );
      }
    },

    showAlert(sucess) {
      if (sucess) {
        Swal.fire({
          icon: "success",
          title: "Sucesso!!",
          showConfirmButton: false,
          timer: 2000,
          didOpen: () => {
            this.liberaLoad = false;
          },
          didClose: () => {
            if (this.resultadoIntegracao) {
              this.resetParametros();
              this.$router.push(
                "/integracoes_skynet/" + this.identificador + "/6"
              );
            }
          },
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Ops, ocorreu um erro.",
          showConfirmButton: false,
          timer: 2000,
          didOpen: () => {
            this.liberaLoad = false;
          },
          didClose: () => {
            if (this.errorAlertIntegr) {
              this.resetParametros();
              this.$router.push(
                "/integracoes_skynet/" + this.identificador + "/6"
              );
            }
          },
        });
      }
    },

    criaNomeAbordagem() {
      for (let abordagem in this.abordagemMemory) {
        if (this.abordagemMemory[abordagem] == true) {
          if (abordagem == "classic") {
            this.nomeAbordagem.push("Logistic Reg.");
          }
          if (abordagem == "lg") {
            this.nomeAbordagem.push("LightGBM");
          }
          if (abordagem == "xg") {
            this.nomeAbordagem.push("XGBoost");
          }
        }
      }
    },

    resetInteg() {
      this.resultadoIntegracao = null;
      this.errorAlertIntegr = "";
      this.textoParametros = "";
      this.amostrasTreino = "";
      this.counter = 0;
    },

    goInteg4() {
      this.$router.push("/integracoes_skynet/" + this.identificador + "/4");
    },

    ...mapActions(useSkynetMemoryStore, [
      "setResultadoIntegracao",
      "resetParametros",
    ]),
  },

  created() {
    this.recuperaDadosUsuario();
    this.criaNomeAbordagem();
    if (!this.projectName) {
      this.$router.push("/integracoes_skynet/" + this.identificador + "/0");
    }
  },

  computed: {
    ...mapWritableState(useSkynetStore, [
      "varReservadas",
      "reservadasAtualizadasMemory",
      "dataRefColumn",
      "docColumn",
    ]),

    ...mapWritableState(useSkynetMemoryStore, [
      "projectName",
      "respMau",
      "varRespMemory",
      "varAmostraMemory",
      "varAmostraTreinoMemory",
      "varReservadasMemory",
      "covarSelecionadasMemory",
      "linguagemMemory",
      "abordagemMemory",
      "resultadoIntegracaoMemory",
      "msgErroIntegracaoMemory",
    ]),
  },
};
</script>

<style  lang="scss" scoped>
.inicial-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}
.disclaimer {
  font-weight: 500;
  font-size: 1.5rem !important;
  text-align: center;
  margin: 0 !important;
  word-wrap: break-all;
}
.id {
  font-style: italic;
  word-wrap: break-all;
  padding: 5px 10px 5px 10px;
  border-radius: 6px;
  font-weight: 600;
}
.content {
  width: 100%;
  height: 740px;
  display: flex;
  flex-direction: row;

  @include mediaQueryMax(medium) {
    min-height: 1050px;
    height: 1300px;
  }
  @include mediaQueryMax(small) {
    height: 1630px;
  }
}
@media (min-width: 1025px) {
  .inicial-container .disclaimer {
    font-size: 2.2rem;
  }
}

.card {
  padding: 10px !important;
  border: none;
  top: -65%;
}
.boxBacktestExt {
  width: 90% !important;
  margin: 10px !important;
  padding: 5px 15px !important;
  display: flex;
  align-items: center;
  @include mediaQueryMax(small) {
    height: 89%;
  }
  @include mediaQueryMax(medium) {
    height: 92%;
  }
}
.boxBacktest {
  background-color: #f9f7ff;
  padding: 0 0 0 0 !important;
  width: 100% !important;
  height: 97%;
  flex-wrap: wrap;
  margin: 10px;
  display: flex;
  flex-direction: column !important;
  justify-content: flex-start;

  @include mediaQueryMax(medium) {
    max-height: 98%;
  }
}

.skynetIcon {
  display: flex;
  align-self: flex-end;
  z-index: 1;
  height: 45px;
}
.skynetLogo {
  height: 35px;
  margin: 10px 0;
}
.label {
  padding: 0px 20px;
  top: -6%;
  height: 75px;

  @include mediaQueryMax(medium) {
    top: -4%;
  }
  @include mediaQueryMax(small) {
    top: -3%;
  }
}
.field {
  height: 80%;
  width: 100%;
  display: flex;
  top: -10%;
  margin: 0%;
  padding: 0 20px;

  @include mediaQueryMax(medium) {
    flex-direction: column;
    top: -7%;
  }
  @include mediaQueryMax(small) {
    padding: 10px;
    top: -3%;
  }
}

.footer {
  height: 1%;
  display: flex;
  justify-content: flex-end;
  background-color: transparent;
  padding: 5px 20px;
  top: -2%;

  @include mediaQueryMax(medium) {
    top: 6%;
  }
  @include mediaQueryMax(small) {
    top: 12%;
    flex-direction: column;
  }
}

.left {
  width: 50%;
  height: 100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  @include mediaQueryMax(small) {
    padding: 0px;
  }
  @include mediaQueryMax(medium) {
    width: 100%;
  }
}
.right {
  width: 50%;
  height: 100%;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  @include mediaQueryMax(small) {
    padding: 0px;
  }
  @include mediaQueryMax(medium) {
    width: 100%;
  }
}

.cardInfo {
  margin: 10px 10px 0px 0px;
  padding: 10px;
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: space-between;

  @include mediaQueryMax(small) {
    margin: 10px 0;
  }
}

.cardField {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.cardFooter {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.textTitle {
  margin: 0px 0 5px 0;
}
.helpIcon {
  cursor: pointer;
  color: #209cee;
  font-size: 1.2rem;
  margin-left: 0.1rem;
}
.infoLote {
  margin: 5px 5px 5px 5px;
  padding: 0px 10px 0px 10px;
  background-color: #ddedf7;
  width: fit-content;
  font-size: 0.8rem;

  @include mediaQueryMax(small) {
    width: 95%;
  }
}

.infos {
  display: flex;
  flex-wrap: wrap;

  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.infoOverflow {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 70px;
}
.infosTitle {
  margin: 5px 5px 0 5px;
}

.fileNameInfoLote {
  @include mediaQueryMax(small) {
    margin: 5px 5px 8px 5px;
  }
}

.buttonCardInfo {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  @include mediaQueryMax(small) {
    flex-direction: column;
    align-items: flex-end;
    padding: 20px 0px 0px 0px;
  }
}

.boxInfo {
  width: 10rem;
  height: 2.5rem;
  margin-right: 0.5rem;
  margin: 0 0.2rem 0 0;
  @include mediaQueryMax(small) {
    width: 100%;
    margin: 0rem 0 0.5rem 0;
  }
}
.router {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.editButton {
  @include flex-row();
  background-color: #209cee;
  width: 100%;
  border-radius: 6px 6px 6px 6px;
  border-color: #209cee;
  font-size: 10px;
  font-weight: 500;
  font-family: inherit;
  align-items: center;
  justify-content: center;
  color: white;
  margin: 0 0 5px 0;
  @include mediaQueryMax(small) {
    margin: 0 0 30px 0;
    height: 35px;
  }
}

.editButton:hover {
  background-color: #2b8ccd;
}
.editButton:active {
  background-color: #2b8ccd;
}

.iconMap {
  color: white;
  font-weight: 300;
  font-size: large;
  margin-right: 2px;
}
.buttonBox {
  display: flex;
  height: 35px;
}

.buttonResume {
  position: relative;
  bottom: 40px;

  @include mediaQueryMax(small) {
    line-height: 50px;
    height: 35px !important;
  }
}

.icon {
  color: #f9f7ff;
  width: 48px;
  height: 48px;
  font-weight: 600;
  font-size: 2.5rem;
}

.spinner {
  // width: 20px;
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 1;
}
</style>