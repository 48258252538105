<template>
  <div class="view-wrapper" v-if="liberaTela">
    <StepsSkynet v-if="verificaRotasSteps('normal')" />

    <div class="view">
      <router-view
        @recuperaLote="recuperaLote"
        @variavelResposta="variavelResposta"
        @variavelAmostra="variavelAmostra"
        :resLote="resLote"
        :cabecalhoMapeadoAPI="cabecalhoMapeadoAPI"
        :cabecalhoMapeadoUser="cabecalhoMapeadoUser"
        :liberaSafraDataRef="liberaSafraDataRef"
        :varRespSelecionada="varRespSelecionada"
        :varAmostraSelecionada="varAmostraSelecionada"
        :projectName="projectName"
      />
    </div>
  </div>
</template>

<script>
import StepsSkynet from "@/components/Visuais/StepsSkynet.vue";
import Swal from "sweetalert2";
import { useSkynetMemoryStore } from "@/stores/skynetMemory";
import { chatOutboxStore } from "@/stores/chat-Outbox.js";
import { mapWritableState, mapActions } from "pinia";

export default {
  components: {
    StepsSkynet,
  },

  data() {
    return {
      identificador: this.$route.params.id,
      liberaTela: false,
      varRespSelecionada: "",
      varAmostraSelecionada: "",
      resLote: null,
      projectName: "",
      cabecalhoMapeadoAPI: [],
      cabecalhoMapeadoUser: [],
      liberaSafraDataRef: false,
    };
  },
  methods: {
    verificaRotasSteps(step) {
      var rota = this.$route.fullPath.slice(-1);
      if (step == "normal") {
        if (parseInt(rota) < 7) {
          return true;
        } else {
          return false;
        }
      }
    },

    recuperaCabecalho() {
      this.resLote[0].mapeamento_cabecalho.split(";").forEach((coluna) => {
        let colunaSeparada = coluna.split("=");
        this.cabecalhoMapeadoAPI.push(colunaSeparada[0]);
        this.cabecalhoMapeadoUser.push(colunaSeparada[1]);
      });
      this.cabecalhoMapeadoAPI.forEach((e) => {
        if (e === "data_ref") {
          this.liberaSafraDataRef = true;
        }
      });
    },

    recuperaLote(l, n) {
      this.resLote = l;
      this.projectName = n;
      this.recuperaCabecalho();
    },

    variavelResposta(r) {
      this.varRespSelecionada = r;
    },

    variavelAmostra(a) {
      this.varAmostraSelecionada = a;
    },

    ...mapActions(chatOutboxStore, ["recebeContexto"]),
  },
  computed: {
    ...mapWritableState(useSkynetMemoryStore, [
      "resultadoIntegracaoMemory",
      "msgErroIntegracaoMemory",
    ]),
  },
  async mounted() {
    this.liberaTela = true;

    const contexto = {
      codigo_agrupador: "",
      rota: this.$route.path,
      nome: this.$route.name,
      descricao: "",
    };

    this.recebeContexto(contexto);
  },

  watch: {},

  created() {},

  beforeRouteLeave(to, from, next) {
    if (
      this.varRespSelecionada &&
      !this.resultadoIntegracaoMemory &&
      !this.msgErroIntegracaoMemory
    ) {
      Swal.fire({
        icon: "warning",
        html: "<font size='5'>Atenção! Ao sair da página os dados preenchidos serão perdidos. </br> </font>",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: '<i class="fas fa-check-circle"></i> Continuar',
        confirmButtonAriaLabel: "Sim!",
        cancelButtonText: '<i class="fas fa-ban"></i> Cancelar',
        cancelButtonAriaLabel: "Cancelar",
        confirmButtonColor: "#2196f3",
        focusConfirm: false,
        allowEnterKey: true,
      }).then((result) => {
        if (result.isConfirmed) {
          next();
        } else {
          next(false);
        }
      });
    } else {
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
.view-wrapper {
  margin-top: 10px;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.view {
  width: 100%;
  display: inline;
  align-items: flex-start;

  @include mediaQueryMax(small) {
    min-height: 1000px;
  }
}

@media (min-width: 1024px) {
  .view-wrapper {
    margin-top: 30px;
    gap: 30px;
  }
}
</style>
