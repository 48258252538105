<template>
  <div class="inicial-container">
    <div class="content container-fluid text-center">
      <div
        class="cardTitle"
        v-bind:style="{ 'background-image': 'url(' + fundoOutbox + ')' }"
      >
        <h3 class="disclaimer">
          Bem-vindo a área de Integração de Lotes com o
          <img :src="skynetLogo" class="skynetLogo" />
        </h3>
      </div>
      <md-card class="boxBacktestExt">
        <md-card class="boxBacktest">
          <md-card-content class="label">
            <p class="intro">
              Este processo irá solicitar a configuração das variáveis para
              integração com a plataforma do SKYNET. <br />
              Para configuração dos parâmetros solicitados na integração com o
              SKYNET, o usuário irá passar pelos seguintes passos:
            </p>
          </md-card-content>
          <md-card-content class="field">
            <div class="steps__container">
              <div class="item">
                <p class="textItem" id="passo1">
                  Passo 1 <br />
                  <span class="material-symbols-outlined iconItem"
                    >task_alt</span
                  >
                </p>
              </div>
              <b-tooltip
                target="passo1"
                triggers="hover"
                placement="bottom"
                variant="dark"
                class="tooltipResults"
                boundary="window"
              >
                <strong class="tooltipResults"> Variável Resposta </strong>
                <br />
                Representa qual a coluna que define a variável resposta no
                processo de modelagem. <br />
              </b-tooltip>
              <div class="item">
                <p class="textItem" id="passo2">
                  Passo 2
                  <span class="material-symbols-outlined iconItem"
                    >task_alt</span
                  >
                </p>
              </div>
              <b-tooltip
                target="passo2"
                triggers="hover"
                placement="bottom"
                variant="dark"
                class="tooltipResults"
                boundary="window"
              >
                <strong class="tooltipResults"> Variável Amostra </strong>
                <br />
                A
                <strong class="tooltipResults">Amostra Principal</strong>
                representa o plano amostral que será utilizado na modelagem.
                <br />
                As
                <strong class="tooltipResults">Amostras Desafiantes</strong>
                representam os cenários secundários de amostras que poderão ser
                utilizados na modelagem.
              </b-tooltip>
              <div class="item">
                <p class="textItem" id="passo3">
                  Passo 3
                  <span class="material-symbols-outlined iconItem"
                    >task_alt</span
                  >
                </p>
              </div>
              <b-tooltip
                target="passo3"
                triggers="hover"
                placement="bottom"
                variant="dark"
                class="tooltipResults"
                boundary="window"
              >
                <strong class="tooltipResults">Variáveis Chave. </strong> <br />
                As
                <strong class="tooltipResults">Variáveis Reservadas</strong> são
                os atributos utilizados como chave de ligação entre bases e
                serão desconsiderados na análise.<br />
                As <strong class="tooltipResults">Covariáveis</strong> são os
                atributos que serão utilizados na análise.<br />
              </b-tooltip>
              <div class="item">
                <p class="textItem" id="passo4">
                  Passo 4
                  <span class="material-symbols-outlined iconItem"
                    >task_alt</span
                  >
                </p>
              </div>
              <b-tooltip
                target="passo4"
                triggers="hover"
                placement="bottom"
                variant="dark"
                class="tooltipResults"
                boundary="window"
              >
                <strong class="tooltipResults"
                  >Linguagem e Abordagem Preditiva.</strong
                >
                <br />
                Seleção da Linguagem e Abordagem(s) que serão utilizadas na
                modelagem SKYNET.
              </b-tooltip>
              <div class="item">
                <p class="textItem" id="passo5">
                  Passo 5
                  <span class="material-symbols-outlined iconItem"
                    >task_alt</span
                  >
                </p>
              </div>
              <b-tooltip
                target="passo5"
                triggers="hover"
                placement="bottom"
                variant="dark"
                class="tooltipResults"
                boundary="window"
              >
                <strong class="tooltipResults">Resumo da Integração. </strong>
                <br />
                Revisão das informações configuradas.
                <strong class="tooltipResults"
                  >Alterações nos passos anteriores podem ocorrer caso o usuário
                  ache necessário.</strong
                >
              </b-tooltip>
              <div class="item">
                <p class="textItem" id="passo6">
                  Passo 6
                  <span class="material-symbols-outlined iconItem"
                    >task_alt</span
                  >
                </p>
              </div>
              <b-tooltip
                target="passo6"
                triggers="hover"
                placement="bottom"
                variant="dark"
                class="tooltipResults"
                boundary="window"
              >
                <strong class="tooltipResults"> Status de Integração. </strong>
              </b-tooltip>
            </div>
            <div class="campoInfo">
              <div class="campoProjeto">
                <h5 class="campoTitle">Projeto Selecionado:</h5>
                <strong class="nomeProjeto"> "{{ projectName }}" </strong>
              </div>
              <div class="campoLote">
                <h5 class="campoTitle">Lote Selecionado:</h5>
                <strong class="nomeLote"> "{{ identificador }}" </strong>
              </div>
            </div>
          </md-card-content>
          <md-card-content class="footer">
            <div class="buttonInit">
              <span id="buttonStart" class="buttonInit">
                <button
                  class="button-backstest"
                  @click="startIntegr"
                  :disabled="!projectName || disableIntegracao"
                >
                  INICIAR
                </button>
              </span>
              <b-tooltip
                v-if="disableIntegracao"
                target="buttonStart"
                triggers="hover"
                placement="bottom"
                variant="dark"
                class="tooltipResults"
                boundary="window"
              >
                Aguarde a finalização das execuções em andamento para configurar
                novas integrações para este Lote
              </b-tooltip>
            </div>
          </md-card-content>
        </md-card>
      </md-card>
    </div>
  </div>
</template>

<script>
import { historicoService } from "@/services";
import { mapWritableState, mapActions } from "pinia";
import { useSkynetMemoryStore } from "@/stores/skynetMemory";
import { skynetService } from "@/services";

export default {
  name: "telaInicialBacktest",

  props: {},
  components: {},

  data() {
    return {
      identificador: this.$route.params.id,
      projectName: "",
      skynetLogo: require("/public/img/SkynetWhite.png"),
      fundoOutbox: require("/public/img/bannerOutbox.png"),
      resLote: null,
      disableIntegracao: true,
    };
  },

  methods: {
    async recuperaLoteInfo() {
      const objetoHistorico = { identificacao_execucao: this.identificador };
      const query = new URLSearchParams(objetoHistorico);

      try {
        this.resLote = await historicoService.recuperaDadosHistoricoLotes(
          query.toString()
        );
      } catch (error) {}
      this.projectName = this.resLote[0].nome_projeto;
      const rotaConsulta = this.resLote[0].produto;
      const abordagemConsulta = this.resLote[0].cod_ref;
      this.setProjectName(this.projectName, this.identificador);
      this.setTipoConsulta(rotaConsulta, abordagemConsulta);

      this.$emit("recuperaLote", this.resLote, this.projectName);
    },

    async recuperaIntegracao() {
      const objetoHistorico = {
        identificacaoExecucao: this.identificador,
      };
      const query = new URLSearchParams(objetoHistorico);
      this.execucoesIntegracao = await skynetService.recuperaDadosIntegracao(
        query.toString()
      );
      this.disableIntegracao = false;
      for (let i in this.execucoesIntegracao) {
        if (this.execucoesIntegracao[i].status == "Submetido") {
          await this.recuperaExecucaoSkynet(this.execucoesIntegracao[i]);
        }
      }
    },

    async recuperaExecucaoSkynet(item) {
      let periodoDefault = 30;
      const objetoHistorico = {
        hashExecucao: item.hash_execucao_skynet,
        periodoEmDias: periodoDefault,
      };
      const query = new URLSearchParams(objetoHistorico);

      this.execucoesSkynet = await skynetService.recuperaExecucoesSkynet(
        query.toString()
      );
      if (
        this.execucoesSkynet[0].Status != "FinalizadoComSucesso" &&
        this.execucoesSkynet[0].Status != "FinalizadoComErro" &&
        this.execucoesSkynet[0].Status != "EmCancelamento" &&
        this.execucoesSkynet[0].Status != "Cancelado"
      ) {
        this.disableIntegracao = true;
      }
    },

    startIntegr() {
      this.resetParametros();
      this.$router.push("/integracoes_skynet/" + this.identificador + "/1");
    },

    ...mapActions(useSkynetMemoryStore, [
      "setProjectName",
      "resetParametros",
      "setTipoConsulta",
    ]),
  },

  created() {
    this.recuperaLoteInfo();
    this.recuperaIntegracao();
  },

  mounted() {},

  computed: {},
};
</script>

<style  lang="scss" scoped>
.inicial-container {
  display: flex;
  gap: 10px;
  align-items: center;
}
.disclaimer {
  font-weight: 700;
  font-size: 2rem !important;
  text-align: center;
  margin: 10px !important;
  word-wrap: break-all;
  color: #fff;
  text-shadow: -1px -1px 0px rgb(0, 0, 0), -1px 1px 0px rgb(0, 0, 0),
    1px -1px 0px rgb(0, 0, 0), 1px 0px 0px rgb(0, 0, 0);
}
.skynetLogo {
  height: 50px;
  margin: 0 2px;
  border-radius: 20px;
  padding: 5px;
}
.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 1025px) {
  .inicial-container .disclaimer {
    font-size: 2.2rem;
  }
}
.cardTitle {
  background-color: #3b3b3b;
  display: flex;
  width: 80%;
  min-height: 90px;
  padding: 10px;
  row-gap: 10px;
  box-shadow: 0px 5px 10px 0.1px rgb(0 0 255 / 30%);
  border-radius: 10px;
  @include flex-center(row);
}
.boxBacktestExt {
  width: 80% !important;
  padding: 5px 15px !important;
  display: flex;
  align-items: center;
  background-color: #f9f7ff;
}
.boxBacktest {
  background-color: #fff;
  padding: 0 0 0 0 !important;
  min-height: 400px;
  max-height: 400px;
  width: 100% !important;
  max-height: 100%;
  flex-wrap: wrap;
  margin: 10px;
  display: flex;
  flex-direction: column !important;
  justify-content: center;

  @include mediaQueryMax(small) {
    min-height: 600px;
  }
}
.label {
  margin: 10px 0px !important;
  padding: 0px 10px;
  top: -18%;
  min-height: 20px;
  width: 100%;
}
.field {
  min-height: 250px;
  width: 100%;
  display: flex;
  top: -20%;
  margin: 0%;
  padding: 0px 0;
  flex-direction: column;
}

.footer {
  min-height: 30px;
  height: 50px;
  width: 100%;
  display: flex;
  background-color: transparent;
  padding: 10px;

  @include mediaQueryMax(small) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 60px;
  }
}
.intro {
  font-size: 1rem;
  margin: 0px 0;
}

.steps__container {
  width: 100%;
  min-height: 160px;
  justify-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  margin: 0 0 20px 0;
  @include mediaQueryMax(large) {
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 1.5rem;
  }
  @include mediaQueryMax(medium) {
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
  }
}

.item {
  width: 110px;
  height: 110px;
  margin: 0px 25px;
  padding: 10px;

  @include mediaQueryMax(small) {
    margin: 0px 10px;
  }
}
.textItem {
  color: #f5f5f5;
  background-color: rgba(31, 134, 219, 0.9);
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 1.6px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: 0.5s ease all;
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
  box-shadow: 0px 5px 10px 0.1px rgb(0 0 255 / 30%);
}
.textItem:hover {
  background-color: rgba(0, 31, 117, 0.7);
  box-shadow: 0px 5px 10px 0.1px rgb(0 0 255 / 50%);
}

.tooltipResults {
  color: #f5f5f5;
}

.iconItem {
  color: #f5f5f5;
  margin: 3px 0 0 0;
}

@media (min-width: 1024px) {
  .item a {
    width: 60px;
    height: 60px;
  }

  .linha {
    width: 70px;
  }
}

.buttonInit {
  width: 100%;
  min-height: 35px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  @include mediaQueryMax(small) {
    min-height: 0;
    width: 100%;
    justify-content: flex-end;
  }
}
.campoInfo {
  display: flex;
  flex-direction: column;
}
.campoProjeto {
  min-width: 40%;
  padding: 10px;
  display: flex;
  
  @include mediaQueryMax(small) {
    flex-direction: column;
  }
}
.campoLote {
  min-width: 60%;
  padding: 10px;
  display: flex;
  @include mediaQueryMax(small) {
    flex-direction: column;
  }
}

.campoTitle {
  margin: 0 10px;
  display: flex;
  align-items: center;
  line-height: 1.5rem;
}
.nomeProjeto {
  font-size: 1rem;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  background-color: rgb(221, 237, 247, 0.7);
  border-radius: 6px;
  padding: 5px 10px;
}
.nomeLote {
  font-size: 1rem;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  background-color: rgb(221, 237, 247, 0.7);
  border-radius: 6px;
  padding: 5px 10px;
  word-break: break-all;
}
</style>